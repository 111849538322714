import React, { useEffect, useRef,useState } from "react";
import { Link } from "react-router-dom";
import { HOME, CENTER, LAP, VDOIMG, celpip, IELTS, PTE, TOEFL, GMAT, SAT, DEMO , GIF} from "../assets/Images/impoimg";
import './HomePage.css';

const HomePage = () => {
    const contentRef = useRef(null);
    const chooseTestRef = useRef(null);
    const thirdPageRef = useRef(null); 
    const fourthSectionRef = useRef(null); 
    const materialSectionRef = useRef(null);
    const fifthPageRef = useRef(null);
    const leftSideRef = useRef(null); 

    const [studentCount, setStudentCount] = useState(100); // Initial state for student count

    useEffect(() => {

        //google review
        const script = document.createElement("script");
        script.src = "https://static.elfsight.com/platform/platform.js";
        script.defer = true;
        script.setAttribute("data-use-service-core", true);
        document.body.appendChild(script);

        const observerOptions = {
            threshold: 0.5,
        };

        const contentElement = contentRef.current;
        const chooseTestElement = chooseTestRef.current;
        const thirdPageElement = thirdPageRef.current; 
        const materialSectionElement = materialSectionRef.current;
        const fourthSectionElement = fourthSectionRef.current;
        const fifthPageElement = fifthPageRef.current;
        const leftSideElement = leftSideRef.current;

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add("in-view");
                    if (entry.target === fourthSectionElement) {
                        startCounting(100, 1286, 2000); 
                    }
                }
            });
        }, observerOptions);

        if (contentElement) observer.observe(contentElement);
        if (chooseTestElement) observer.observe(chooseTestElement);
        if (thirdPageElement) observer.observe(thirdPageElement); 
        if (materialSectionElement) observer.observe(materialSectionElement);
        if (fourthSectionElement) observer.observe(fourthSectionElement);
        if (fifthPageElement) observer.observe(fifthPageElement);
        if (leftSideElement) observer.observe(leftSideElement);


        return () => {
            if (contentElement) observer.unobserve(contentElement);
            if (chooseTestElement) observer.unobserve(chooseTestElement);
            if (thirdPageElement) observer.unobserve(thirdPageElement); // Unobserve the third page
            if (materialSectionElement) observer.unobserve(materialSectionElement);
            if (fourthSectionElement) observer.unobserve(fourthSectionElement);
            if (fifthPageElement) observer.unobserve(fifthPageElement);
            if (leftSideElement) observer.unobserve(leftSideElement);

            //google review
            document.body.removeChild(script);

        };
    }, []);

    const startCounting = (start, end, duration) => {
        let startTime = null;

        const countStep = (timestamp) => {
            if (!startTime) startTime = timestamp;
            const progress = timestamp - startTime;
            const current = Math.min(start + Math.floor(progress / duration * (end - start)), end);
            setStudentCount(current);
            if (current < end) {
                requestAnimationFrame(countStep);
            }
        };

        requestAnimationFrame(countStep);
    };


    return (
        <div className="home-page-container">
            <div className="content" ref={contentRef}>
                <div className="text-content">
                    <h1 className="title">
                        Test Preparation With <span className="highlight"><br/>AI</span> Scoring
                    </h1>
                    <div className="button-group">
                        <Link to={'/signup'} className="button join-us">
                            Join Now 
                        </Link>
                    </div>
                </div>
                <div className="image-container">
                    <img src={HOME} alt="Home" className="home-image" />
                </div>
            </div>
            
            <div className="choose-test-section" ref={chooseTestRef}>
                <h2 className="choose-test-title">Choose Your Test</h2>
                <div className="test-section-wrapper">
                    <div className="test-options left">
                        <Link to="/celpip" className="test-option">
                            <img src={celpip} alt="CELPIP" />
                        </Link>
                        <Link to="/ielts" className="test-option">
                            <img src={IELTS} alt="IELTS" />
                        </Link>
                        <Link to="/toefl" className="test-option">
                            <img src={TOEFL} alt="TOEFL" />
                        </Link>
                    </div>

                    <div className="center-image-container">
                        <img src={CENTER} alt="Center" className="center-image" />
                    </div>

                    <div className="test-options right">
                        <Link to="/pte" className="test-option">
                            <img src={PTE} alt="PTE" />
                        </Link>
                        
                        <Link to="/sat" className="test-option">
                            <img src={SAT} alt="SAT" />
                        </Link>
                        <Link to="/gmat" className="test-option">
                            <img src={GMAT} alt="GMAT" />
                        </Link>
                    </div>
                </div>
            </div>

           {/* Third Page Section */}
           <h2 className="third-page-title">We Are Providing</h2>
            <div className="third-page-section" ref={materialSectionRef}>
                <div className="third-page-content">
                    <div className="third-page-image-container">
                        <img src={LAP} alt="Centered" className="third-page-centered-image" />
                    </div>
                    <div className="third-page-gif-container">
                        <img src={GIF} alt="Gif" className="third-page-gif-image" />
                    </div>
                </div>
            </div>
            {/* Fourth Section */}
            <div className="fourth-section" ref={fourthSectionRef}>
                <div className="image-container">
                    <img src={VDOIMG} alt="Mobile" className="mobile-image" />
                </div>
                <div className="content-container">
                    <h2 className="registered-text">ALREADY REGISTERED</h2>
                    <h1 className="students-number">{studentCount}</h1>
                    <h3 className="students-text">STUDENTS</h3>
                    <p className="details-text">DETAILED SCORE ANALYSIS</p>
                    <p className="feedback-text">FEEDBACK THROUGH SCORES</p>
                    <p className="tests-text">FULL AND SECTIONAL TESTS FOR ALL COURSES</p>
                    <Link to={'/signup'} className="button get-started-button">
                        Get Started
                    </Link>
                </div>
            </div>

            {/* Fifth Page Section */}
            <div className="fifth-page" ref={fifthPageRef}>
            <div className="left-side" ref={leftSideRef}>
            <img src={DEMO} alt="Advanced Learning Modules" className="large-image" />
                </div>
                <div className="right-side">
                    <h1 className="join-classes-title">Join Classes</h1>
                    <Link to={'/signup'} className="animated-button">
                    Free Demo Class
                    </Link>
                    <div className="dynamic-text">
                <p>Join the class with master trainers material access included</p>
                </div>
                </div>
            </div>

            {/* Google Reviews Section */}
            <div className="review-section">
            <h2 className="reviews-heading">Reviews by our students</h2>
            <div
                className="elfsight-app-8d6d478d-e9fb-44c7-a77a-e650cf51b687" data-elfsight-app-lazy></div>
            </div>

            <footer className="homepage-footer">
            <div className="footer-border-animation"></div>
            <div className="footer-links">
                <Link to="/terms" className="footer-link">Terms of Service</Link>
                <Link to="/privacy" className="footer-link">Privacy Policy</Link>
                <Link to="/terms" className="footer-link">Disclaimer</Link>
                <Link to="/terms" className="footer-link">Refund & Cancellation Policy</Link>
            </div>
        </footer>

        </div>
    );
};

export default HomePage;
