import React, { useState, useEffect, useRef } from 'react';
import './SpeakingTest.css';
import { fetchQuestions } from '../../api/index';
import { startRecording as startRecordingUtility, stopRecording as stopRecordingUtility } from './Common';
import ScoreGraph from './ScoreGraph'; 

import startAudio from '../../assets/Audios/startaudio.mp3';
import timeUpAudio from '../../assets/Audios/timeup.mp3';
import LoadingAnimation from '../../pages/LoadingAnimation';

const SpeakingTest = () => {
  const [questions, setQuestions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [prepareTime, setPrepareTime] = useState(0);
  const [recordingTime, setRecordingTime] = useState(0);
  const [isRecording, setIsRecording] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const [isRecordingDone, setIsRecordingDone] = useState(false);
  const [imageSelectionTime, setImageSelectionTime] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showThirdImage, setShowThirdImage] = useState(false);
  const [isStopping, setIsStopping] = useState(false);
  const [transcription, setTranscription] = useState(null);

  const [transcriptionData, setTranscriptionData] = useState(null);
  const [analysis, setAnalysis] = useState(null);
  const [scores, setScores] = useState({}); // Declare scores using useState
  const [showGraph, setShowGraph] = useState(false); // State to control showing the graph
  const [isLoading, setIsLoading] = useState(false); // Add loading state
  const [autoSelectTimeout, setAutoSelectTimeout] = useState(null);


  
  const audioContextRef = useRef(null);
  const gumStreamRef = useRef(null);
  const recRef = useRef(null);
  const recordingIntervalRef = useRef(null);

  //audio play 
  const playAudio = (audioSrc) => {
    const audio = new Audio(audioSrc);
    audio.play().catch((error) => {
      console.error('Error playing audio:', error);
    });
  };

  // Fetch questions
  useEffect(() => {
    const getQuestions = async () => {
      try {
        const { data } = await fetchQuestions();
        setQuestions(data);
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    };
    getQuestions();
  }, []);

  // When the current index changes, reset the question, preparation time, and recording time
  useEffect(() => {
    if (currentIndex !== null) {
      const question = questions[currentIndex];
      setCurrentQuestion(question);
      setPrepareTime(question.preparationTime);
      setRecordingTime(question.recordingTime);
      resetStates(); // Reset transcription, analysis, etc.
      setShowThirdImage(false); // Reset image selection
    }
  }, [currentIndex, questions]);

  // Function to reset transcription, analysis, scores, and audio preview
  const resetStates = () => {
    setTranscription(null);
    setAnalysis(null);
    setScores({});
    setAudioUrl(null);
    setIsRecordingDone(false);
    setShowGraph(false);
  };

  // Countdown for preparation time
  useEffect(() => {
    if (prepareTime > 0) {
      const countdownInterval = setInterval(() => {
        setPrepareTime((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(countdownInterval);
            startActualRecording(); // Start recording after preparation time ends
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [prepareTime]);

  // Countdown for image selection time (if applicable)
  useEffect(() => {
    if (imageSelectionTime > 0) {
      const selectionCountdown = setInterval(() => {
        setImageSelectionTime((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(selectionCountdown);
            setShowThirdImage(true); // Show third image after selection countdown
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);

      return () => clearInterval(selectionCountdown);
    }
  }, [imageSelectionTime]);

  // Function to handle the logic for Task 5 image selection
const handleTask5NextImage = () => {
  if (currentIndex === 4) {
    if (!selectedImage) {
      // If no image is selected, default to the first image
      setSelectedImage('img1');
    }
    // Now show the third image along with the selected one
    setShowThirdImage(true);

    // Reset preparation time for the new image preview
    setPrepareTime(currentQuestion.preparationTime);

    // Start a new countdown for the preview images before recording starts
    setTimeout(() => {
      // Start the recording countdown only after preview time ends
      startActualRecording();
    }, currentQuestion.preparationTime * 1000); // After preview countdown
  }
};

 // Effect to handle automatic selection if the user does not select an image in time
 useEffect(() => {
  if (currentIndex === 4 && selectedImage === null) {
    // Set a timeout for automatic selection
    const timeout = setTimeout(() => {
      setSelectedImage('img1'); // Automatically select the first image
      handleTask5NextImage(); // Show third image and start countdown
    }, 60000); // 60 seconds (or your desired timeout duration)

    // Cleanup the timeout if the component unmounts or if user selects an image
    return () => clearTimeout(timeout);
  }
}, [currentIndex, selectedImage]);

  // Recording countdown
  useEffect(() => {
    if (isRecording) {
      recordingIntervalRef.current = setInterval(() => {
        setRecordingTime((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(recordingIntervalRef.current);
            debouncedStopActualRecording(); // Stop recording after full time
            playAudio(timeUpAudio);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }

    // Cleanup intervals
    return () => {
      if (recordingIntervalRef.current) {
        clearInterval(recordingIntervalRef.current);
      }
    };
  }, [isRecording]);

  // Start the test by setting the first question
  const startTest = () => {
    setCurrentIndex(0);
  };

  // Navigate to the next question
  const nextQuestion = () => {
    if (currentIndex < questions.length - 1) {
      saveTestAttempt(); // Save the current attempt before navigating
      setCurrentIndex((prevIndex) => prevIndex + 1);
    } else {
      alert('Test completed!');
    }
  };

  // Navigate back to the previous question
  const goBack = () => {
    if (currentIndex > 0) {
      saveTestAttempt(); // Save the current attempt before navigating
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

   
  // Start the actual recording
  const startActualRecording = () => {
    setIsRecording(true);
    playAudio(startAudio);
    startRecordingUtility(audioContextRef, gumStreamRef, recRef, setRecordingTime);
  };

  //const API_URL = 'http://35.182.60.251:5000';
   const API_URL = 'https://engdemyeducation.com';
  //const API_URL = 'http://localhost:5000';

  // Stop the actual recording
  const stopActualRecording = async () => {
    if (isStopping || !isRecording) return; // Prevent double execution
    setIsStopping(true);
    setIsRecording(false);
    setIsRecordingDone(true);

    const audioBlob = await stopRecordingUtility(recRef);
    //setIsStopping(false);
    const audioFile = new File([audioBlob], "audio.webm", { type: "audio/webm" });

     // Initialize formData here
  const formData = new FormData();
  formData.append("audio", audioBlob, "audio.webm"); // Append audio file to formData

    setAudioUrl(URL.createObjectURL(audioBlob)); // Preview the recorded audio here

    let transcriptionData = null; 

    try {
      // Start loading
      setIsLoading(true);
          // 1. Upload the audio to the backend
      const uploadResponse = await fetch(`${API_URL}/upload`, {
        method: "POST",
        body: formData,
      });
  
      if (!uploadResponse.ok) {
        throw new Error(`Failed to upload: ${uploadResponse.statusText}`);
      }
  
      const uploadData = await uploadResponse.json();
    //   setAudioUrl(URL.createObjectURL(audioBlob)); // Preview the recorded audio here
  
    //    // Send the uploaded audio file to OpenAI for transcription
    // const transcriptionFormData = new FormData();
    // transcriptionFormData.append('file', audioBlob, 'audio.webm');

    // 2. Transcribe the uploaded audio using OpenAI
    const transcriptionResponse = await fetch(`${API_URL}/upload/transcribe`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ filePath:  uploadData.filePath  })
      });
  
      if (!transcriptionResponse.ok) {
        throw new Error(`Failed to transcribe: ${transcriptionResponse.statusText}`);
      }
  
      transcriptionData = await transcriptionResponse.json();
      setTranscription(transcriptionData.transcription); // Display transcription below the preview
  
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
      setIsStopping(false); // Reset flag after execution
    }

  // 3. Analyze the transcription if transcription data is available
  if (transcriptionData && transcriptionData.transcription) {
        try {
          setIsLoading(true); // Set loading true again for analysis
        // Fetch analysis based on transcription
        const analysisResponse = await fetch(`${API_URL}/analyze`, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ transcription: transcriptionData.transcription })
        });

        if (!analysisResponse.ok) {
          throw new Error(`Failed to analyze: ${analysisResponse.statusText}`);
        }

        const analysisData = await analysisResponse.json();
        setAnalysis(analysisData.analysis); // Set analysis to display it

       // Use the extracted scores directly from the backend
      const extractedScores = analysisData.extractedScores;
      setScores(extractedScores);

        // Save the test attempt
        await saveTestAttempt(transcriptionData.transcription, extractedScores, audioBlob);
      } catch (error) {
        console.error("Error analyzing transcription:", error);
      } finally {
        setIsLoading(false); // Stop loading after analysis
        setIsStopping(false); // Reset flag after execution
      }
      
      } else {
        console.error("Transcription data is not available for analysis.");
      }

      };
  
      // After getting transcription and analysis
const saveTestAttempt = async (transcription, scores, audioBlob ) => {
  try {
    const userInfo = JSON.parse(localStorage.getItem('user_info'));
    const userId = userInfo?.result?._id; // Get the user ID
    const questionId = currentQuestion?._id; // Get question ID

    const formData = new FormData();
    formData.append("audio", audioBlob, "audio.webm");
    formData.append("transcription", transcription);
    formData.append("userId", userId);
    formData.append("questionId", questionId);

  // Add scores to formData
  Object.keys(scores).forEach(key => {
    formData.append(key, scores[key]);
  });

      const response = await fetch(`${API_URL}/api/saveTestAttempt`, {
          method: "POST",
          body: formData,
        });

      if (!response.ok) {
        const errorData = await response.json(); // Get error details from the response
        console.error("Failed to save test attempt:", errorData);  
        throw new Error(`Failed to save test attempt: ${response.statusText}`);
      }

      // const responseData = await response.json();
      // const audioFileId = responseData.audioFileId; // Get the audio file ID

      // Set the audio URL for previewing
      // setAudioUrl(`${API_URL}/api/audio/${audioFileId}`);
      
      console.log("Test attempt saved successfully");
  } catch (error) {
      console.error("Error saving test attempt:", error);
  }
};
     
  const debounce = (func, delay) => {
    let timeout;
    return function (...args) {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  };
  const debouncedStopActualRecording = debounce(stopActualRecording, 300);

  const handleImageSelection = (image) => {
    setSelectedImage(image);
  };

  return (
    <div className="speaking-test-wrapper">
      {currentIndex === null ? (
        <section className="speaking-test-section speaking-test-instructions">
          <div className="speaking-test-instructions-heading">Speaking Test Instructions</div>
          <article>
            <ul className="speaking-test-instructions">
              <li>Use a timer to complete each task within the given time.</li>
              <li>Scores are not provided for this sample test, but answers are recorded.</li>
              <li>You can click "Next" to move to the next task.</li>
            </ul>
            <div>
              <button onClick={startTest}>START</button>
            </div>
          </article>
        </section>
      ) : (
        <section className="speaking-test-section speaking-test-question">
          <article>
            <table className="speaking-test-question">
              <tbody>
                <tr className="task-details">
                  <th className="task-info">Task Number: {currentQuestion?.taskNumber}</th>
                  <th className="task-info right-align">
                    Preparation: {currentQuestion?.preparationTime || 0} seconds
                    Recording: {currentQuestion?.recordingTime || 0} seconds
                  </th>
                  <button className="next-button" onClick={() => {
                    if (currentIndex === 4 && !showThirdImage) {
                      handleTask5NextImage(); // Show third image and restart countdown
                    } else {
                      nextQuestion(); // For all other tasks
                    }
                  }}>
                    NEXT
                  </button>
                </tr>
                <tr>
                  <td colSpan={2} className="task-title">
                    Task Title: {currentQuestion?.taskTitle}
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} className="question-title">
                    Question Title: {currentQuestion?.questionTitle || ''}
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} className="question-content">
                    {currentIndex === 4 && showThirdImage ? (
                      currentQuestion?.questionContentExtra || ''
                    ) : (
                      currentQuestion?.questionContent || ''
                    )}
                  </td>
                </tr>
                {/* Extra content */}
                {currentQuestion?.extra && (
                  <tr>
                    <td colSpan={2} className="extra-content">
                      {currentQuestion.extra}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <hr />

            {/* Image Selection for Task 5 */}
            <div className="speaking-test-picture-area">
                {currentIndex === 4 ? (
                  <>
                    {!showThirdImage ? (
                      <>
                        <figure>
                          <img
                          src={`${API_URL}/images/Task5/${currentQuestion.img}`}
                          alt="undefine"
                            onClick={() => handleImageSelection('img1')}
                            style={{ border: selectedImage === 'img1' ? '10px solid green' : 'none' }}
                          />
                          <figcaption>{currentQuestion.imgCaption || ''}</figcaption>
                        </figure>
                        <figure>
                          <img
                          src={`${API_URL}/images/Task5/${currentQuestion.img2}`}
                          alt="un2"
                            onClick={() => handleImageSelection('img2')}
                            style={{ border: selectedImage === 'img2' ? '10px solid green' : 'none' }}
                          />
                          <figcaption>{currentQuestion.img2Caption || ''}</figcaption>
                        </figure>
                      </>
                    ) : (
                      <>
                        {selectedImage === 'img1' && currentQuestion?.img && (
                          <figure>
                            <img
                            src={`${API_URL}/images/Task5/${currentQuestion.img}`}
                            alt="5th 1"
                            />
                            <figcaption>{currentQuestion.imgCaption || ''}</figcaption>
                          </figure>
                        )}
                        {selectedImage === 'img2' && currentQuestion?.img2 && (
                          <figure>
                            <img
                            src={`${API_URL}/images/Task5/${currentQuestion.img2}`}
                            alt="5th 2"
                            />
                            <figcaption>{currentQuestion.img2Caption || ''}</figcaption>
                          </figure>
                        )}
                        {currentQuestion?.img3 && (
                          <figure>
                            <img
                            src={`${API_URL}/images/Task5/${currentQuestion.img3}`}
                            alt="5th 3"
                            />
                            <figcaption>{currentQuestion.img3Caption || ''}</figcaption>
                          </figure>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                     {currentQuestion?.img && (
                      <figure>
                        <img
                        src={`${API_URL}/images/Task${currentQuestion.taskNumber}/${currentQuestion.img}`}
                        alt="nrml 1"
                        />
                      </figure>
                    )}
                    {currentQuestion?.img2 && (
                      <figure>
                        <img
                        src={`${API_URL}/images/Task${currentQuestion.taskNumber}/${currentQuestion.img2}`}
                        alt="nrm 2"
                        />
                      </figure>
                    )}
                  </>
                )}
              </div>

              <div className="speaking-test-cnt-area">
              {prepareTime > 0 ? (
                <div className="speaking-test-countdown-form">
                  <span>Countdown:</span>
                  <br />
                  <span id="cntNumber">{prepareTime}</span>
                </div>
              ) : (
                <>
                  {isRecording ? (
                    <div className="speaking-test-recording-form">
                      <span>Recording:</span>
                      <br />
                      <progress
                        className="speaking-test-progress-bar"
                        value={currentQuestion?.recordingTime - recordingTime}
                        max={currentQuestion?.recordingTime}
                      ></progress>
                    </div>
                  ) : (
                    <div id="doneMessage">Recording Done...</div>
                  )}
                </>
              )}
            </div>

            {/* Preview Recorded Audio */}
            {audioUrl && (
            <div className="audio-preview">
              <p>Preview your recording:</p>
              <audio controls src={audioUrl} />

              {isLoading ? (
            <LoadingAnimation />
          ) : (
            <div>
              {transcription && (
                <div className="transcription-preview">
                  <p>Transcription:</p>
                  <p>{transcription}</p>
                </div>
              )}
              {/* {analysis && (
                  <div className="analysis-preview">
                      <h3>Analysis:</h3>
                      <p>{analysis}</p>
                      <h4>Scores:</h4>
                      <ul>
                        <li>
                          Task Fulfillment: {scores.taskFulfillmentScore}/12  
                          <br />
                          <strong>Justification:</strong> {scores.taskFulfillmentJustification}
                        </li>
                        <li>
                          Coherence: {scores.coherenceScore}/12  
                          <br />
                          <strong>Justification:</strong> {scores.coherenceJustification}
                        </li>
                        <li>
                          Vocabulary: {scores.vocabularyScore}/12  
                          <br />
                          <strong>Justification:</strong> {scores.vocabularyJustification}
                        </li>
                        <li>
                          Grammar: {scores.grammarScore}/12  
                          <br />
                          <strong>Justification:</strong> {scores.grammarJustification}
                        </li>
                        <li>
                          Overall Score: {scores.overallScore}/12  
                          <br />
                          <strong>Justification:</strong> {scores.overallJustification}
                        </li>
                      </ul>
                  </div>
              )} */}
              {analysis && scores && (
              <button onClick={() => setShowGraph(true)}>Result</button>
            )}

            {showGraph && (
              <div className="popup-overlay">
              <div className="popup-content">
                <button className="close-button" onClick={() => setShowGraph(false)}>
                  &times; {/* "X" symbol for the close button */}
                </button>
                <ScoreGraph scores={scores} />
              </div>
            </div>
            )}           
            </div>
          )}
        </div>
      )}
            
            <button className="speaking-test-back-button" onClick={goBack}>BACK</button>
          </article>
        </section>
      )}
    </div>
  );
};

export default SpeakingTest;
