import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoginStyles from "./Login.module.css";
import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import { signinGoogle, signin } from "../../redux/actions/auth";
import Particle from '../../componets/Particle';
import option1 from "../../assets/json/option1.json";
import { toast } from 'react-toastify';

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  function handleGoogleLoginSuccess(tokenResponse) {
    const accessToken = tokenResponse.access_token;
    dispatch(signinGoogle(accessToken, navigate));
    // toast.success("Google login successful"); 
  }

  function handleGoogleLoginError(error) {
    console.error("Google login error:", error);
    toast.error("Google login failed");
  }

  const login = useGoogleLogin({
    onSuccess: handleGoogleLoginSuccess,
    onError: handleGoogleLoginError
  });

  function handleSubmit(e) {
    e.preventDefault();
    if (email === "" || password === "") {
      toast.error("Please fill in all fields.");
      return;
    }
    dispatch(signin({ email, password }, navigate))
      .catch(err => {
        // Toast error handling is done inside the auth action file
      });
  }

  return (
    <div className={LoginStyles.loginContainer}>
      <div className={LoginStyles.loginContainerv2}>
        <Particle option={option1} />
        <h1>Welcome back</h1>

        <div className={LoginStyles.inputContainer}>
          <label>EMAIL</label>
          <input
            onChange={e => setEmail(e.target.value)}
            placeholder="Enter your email"
            type="email"
          />
        </div>

        <div className={LoginStyles.inputContainer}>
          <label>PASSWORD</label>
          <input
            onChange={e => setPassword(e.target.value)}
            placeholder="Enter your password"
            type="password"
          />
        </div>

        <div className={LoginStyles.forgetmeContainer}>
          <div>
            <Link to="/forgotpassword">Forgot password?</Link>
          </div>
        </div>

        <button onClick={handleSubmit} className={LoginStyles.loginBTN}>
          LOGIN
        </button>
        <span className={LoginStyles.or}>or</span>
        <button onClick={() => login()} className={LoginStyles.googleBTN}>
          <i className="fa-brands fa-google"></i> Sign in with Google
        </button>

        <span className={LoginStyles.notreg}>
          Not registered yet? <Link className={LoginStyles.singupBTN} to="/signup">Signup</Link>
        </span>
      </div>
    </div>
  );
}

export default Login;
