import axios from "axios";
//"http://localhost:5000" 
//"http://35.182.60.251:5000"
//"https://engdemyeducation.com"
const API = axios.create({ baseURL: "https://engdemyeducation.com" });

API.interceptors.request.use((req) => {
    const userInfo = localStorage.getItem("user_info");

    if (userInfo) {
        try {
            const parsedUser = JSON.parse(userInfo);
            if (parsedUser.token) {
                req.headers.Authorization = `Bearer ${parsedUser.token}`;
            }
        } catch (error) {
            console.error("Failed to parse user token:", error);
        }
    }

    return req;
});

export const signIn = (data) => API.post("/users/login", data); 
export const signInGoogle = (accessToken) => API.post("/users/login", { googleAccessToken: accessToken }); 
export const signUp = (data) => API.post("/users/signup", data); 
export const signUpGoogle = (accessToken) => API.post("/users/signup", { googleAccessToken: accessToken });

export const getUserById = (userId) => API.get(`/users/getUserById/${userId}`);
export const updateUser = (userId, data) => API.put(`/users/updateUser/${userId}`, data);

export const updatePassword = (userId, data) => API.put(`/users/updatePassword/${userId}`, data);

// Question-related API call
export const fetchQuestions = () => API.get("/questions");

export const verifyEmail = async (emailData) => {
    return await axios.post('/verify-email', emailData);
  };
  