import React, { useState } from "react";
import SignUp from "./Signup.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import { signup, signupGoogle } from "../../redux/actions/auth";
import Particle from '../../componets/Particle';
import option2 from "../../assets/json/option2.json";
import { toast } from 'react-toastify';

const InitState = {
  firstName: "",
  lastName: "",
  email: '',
  password: '',
  confirmPassword: ''
};

function Signup() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sForm, setSForm] = useState(InitState);
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleChange = (e) => {
    setSForm({
      ...sForm,
      [e.target.name]: e.target.value
    });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleGoogleLoginSuccess = (tokenResponse) => {
    const accessToken = tokenResponse.access_token;
    dispatch(signupGoogle(accessToken, navigate))
      .then(() => {
        toast.success("Google signup successful");
      })
      .catch(err => {
        toast.error(err.response?.data?.message || "Google signup failed");
      });
  };

  const handleGoogleLoginError = (error) => {
    toast.error("Google signup error");
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

     // Check if all fields are filled
    if (!sForm.firstName.trim()) {
      toast.error("Please enter your first name.");
      return;
    }
    if (!sForm.lastName.trim()) {
      toast.error("Please enter your last name.");
      return;
    }
    if (!sForm.email.trim()) {
      toast.error("Please enter your email.");
      return;
    }
    if (!validateEmail(sForm.email)) {
      toast.error("Please enter a valid email.");
      return;
    }
    if (!sForm.password.trim()) {
      toast.error("Please enter your password.");
      return;
    }
    if (!sForm.confirmPassword.trim()) {
      toast.error("Please confirm your password.");
      return;
    }
    if (sForm.password.length < 4) {
      toast.error("Password must be at least 4 characters long.");
      return;
    }
    if (sForm.password !== sForm.confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }
    if (!isChecked) {
      toast.error("You must agree to the terms and conditions.");
      return;
    }

        try {
          dispatch(signup(sForm, navigate));
          setSForm(InitState); // Clear form fields after signup
      } catch (err) {
          toast.error(err.response?.data?.message || "Signup failed");
      }
  
  
  };

  const login = useGoogleLogin({
    onSuccess: handleGoogleLoginSuccess,
    onError: handleGoogleLoginError
  });

  return (
    <div className={SignUp.loginContainer}>
      <Particle option={option2} />

      <div className={SignUp.loginContainerv2}>
        <h1>Create your account</h1>

        <div className={SignUp.inputContainer}>
          <label>FIRST NAME</label>
          <input
            onChange={handleChange}
            name="firstName"
            placeholder="Enter your first name"
            type="text"
            value={sForm.firstName}
          />
        </div>
        <div className={SignUp.inputContainer}>
          <label>LAST NAME</label>
          <input
            name="lastName"
            onChange={handleChange}
            placeholder="Enter your last name"
            type="text"
            value={sForm.lastName}
          />
        </div>
        <div className={SignUp.inputContainer}>
          <label>EMAIL</label>
          <input
            name="email"
            onChange={handleChange}
            placeholder="Enter your email"
            type="email"
            value={sForm.email}
          />
        </div>

        <div className={SignUp.inputContainer}>
          <label>PASSWORD</label>
          <input
            name="password"
            onChange={handleChange}
            placeholder="must be 4+ characters"
            type="password"
            value={sForm.password}
          />
        </div>

        <div className={SignUp.inputContainer}>
          <label>CONFIRM PASSWORD</label>
          <input
            name="confirmPassword"
            onChange={handleChange}
            placeholder="Retype your password"
            type="password"
            value={sForm.confirmPassword}
          />
        </div>

        <div className={SignUp.footerContainer}>
          <div>
            Already signed up? <Link to="/login">Login</Link>
          </div>
          <div>
            <Link to="/forgotpassword">Forgot Password?</Link>
          </div>
        </div>

        {/* Terms and Conditions */}
        <div className={SignUp.termsContainer}>
          <input type="checkbox" id="tcs" onChange={handleCheckboxChange} checked={isChecked} />
          <label htmlFor="tcs">
            By clicking Register, you are agreeing to the{" "}
            <Link to="/terms" className={SignUp.link}>T&Cs</Link> and{" "}
            <Link to="/privacy" className={SignUp.link}>Privacy Policy</Link>.
          </label>
        </div>

        <button onClick={handleOnSubmit} className={SignUp.loginBTN}>REGISTER</button>
        <span className={SignUp.or}>or</span>
        <button onClick={() => login()} className={SignUp.googleBTN}>
          <i className="fa-brands fa-google"></i> Sign up with Google
        </button>
      </div>
    </div>
  );
}

export default Signup;
