import { AUTH } from "../const/actionsTypes";
import * as api from "../../api/index";
import { toast } from 'react-toastify';

export const loadUser = () => async (dispatch) => {
    const localUser = JSON.parse(localStorage.getItem("user_info"));
    if (localUser) {
        dispatch({ type: AUTH, data: localUser });
    }
};

export const signin = (data2, navigate) => async (dispatch) => {
    try {
        const { data } = await api.signIn(data2);
        dispatch({ type: AUTH, data });
        navigate("/");
        toast.success("Login successful!");
    } catch (err) {
        const errorMessage = err.response?.data?.message || "Login failed";
        console.error("Login error:", errorMessage);
        toast.error(errorMessage);
    }
};

export const signinGoogle = (accessToken, navigate) => async (dispatch) => {
    try {
        const { data } = await api.signInGoogle(accessToken);
        dispatch({ type: AUTH, data });
        navigate("/");
        toast.success("Google login successful!");
    } catch (err) {
        const errorMessage = err.response?.data?.message || "Google login failed";
        console.error("Google login error:", errorMessage);
        toast.error(errorMessage);
    }
};

export const signup = (formData, navigate) => async (dispatch) => {
    try {
        await api.signUp(formData);
        toast.success("Signup successful! Please check your email to verify your account.");
        navigate('/login'); // Navigate to login after sending the verification email
    } catch (err) {
        const errorMessage = err.response?.data?.message || "Signup failed";
        toast.error(errorMessage);
    }
};


export const signupGoogle = (accessToken, navigate) => async (dispatch) => {
    try {
        console.log("Google Access Token:", accessToken);
        const { data } = await api.signUpGoogle(accessToken);

        if (data) {
            localStorage.setItem('authData', JSON.stringify(data));
        }

        dispatch({ type: AUTH, data });
        navigate("/");
        toast.success("Google signup successful!");
    } catch (err) {
        const errorMessage = err.response?.data?.message || "Google signup failed";
        console.error("Google signup error:", errorMessage);
        toast.error(errorMessage);
    }
};

export const verifyEmail = (token) => async (dispatch) => {
    try {
        await api.verifyEmail(token);
        toast.success("Email verified! You can now log in.");
    } catch (error) {
        toast.error("Verification failed. Token might be invalid or expired.");
    }
};
