import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import { LOGOUT } from '../redux/const/actionsTypes';
import './HomeLayout.css';
import { toast } from 'react-toastify';
import Engdemy from "../assets/Images/Engdemy.png";

const HomeLayout = ({ children, auth }) => {
    const [authenticated, setAuthenticated] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const savedAuthData = JSON.parse(localStorage.getItem('authData'));
        setAuthenticated(!!savedAuthData || !!auth.authData);
    }, [auth]);

    const handleLogOut = (e) => {
        e.preventDefault();
        dispatch({ type: LOGOUT });
        localStorage.removeItem('authData');
        toast.success("Logged out successfully");
        setAuthenticated(false);
        navigate('/');
    };

    const navigateToProfile = () => {
        navigate('/profile');
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const closeSidebar = () => {
        setIsSidebarOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (event.target.closest('.sidebar') === null && event.target.closest('.sidebar-toggle') === null) {
                closeSidebar();
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div className={`layout ${isScrolled ? 'scrolled' : ''}`}>
            <header className={`navbar ${isScrolled ? 'navbar-scrolled' : ''}`}>
                <div className="navbar-content">
                    <Link to="/" className="logo">
                        <img src={Engdemy} alt="Home" className="logo-image" />
                        <h3>Engdemy Education Inc</h3>
                    </Link>
                    <div className={`center-options ${isSidebarOpen ? 'hidden' : ''}`}>
                        <nav className="nav-links">
                            <Link to="/">Home</Link>
                            <Link to="/course">Courses</Link>
                            <Link to="/contact">Contact Us</Link>
                            <Link to="/about">About Us</Link>
                        </nav>
                    </div>
                    <div className={`right-options ${isSidebarOpen ? 'hidden' : ''}`}>
                        {authenticated && (
                            <>
                                <span onClick={navigateToProfile} className="profile-icon">
                                    <i className="fas fa-user"></i> {auth.authData.username}
                                </span>
                                <Link to="/" onClick={handleLogOut} className="logout">
                                    Logout
                                </Link>
                            </>
                        )}
                        {!authenticated && (
                            <>
                                <Link to="/login" className="login">Login</Link>
                                <Link to="/signup" className="signup">Signup</Link>
                            </>
                        )}
                    </div>
                    <button className="sidebar-toggle" onClick={toggleSidebar}>
                        <i className="fas fa-bars"></i>
                    </button>
                </div>
            </header>

            {isSidebarOpen && (
                <div className="sidebar">
                    <button className="sidebar-close" onClick={closeSidebar}>×</button>
                    <nav className="sidebar-links">
                        <Link to="/" onClick={closeSidebar}>Home</Link>
                        <Link to="/course" onClick={closeSidebar}>Courses</Link>
                        <Link to="/contact" onClick={closeSidebar}>Contact Us</Link>
                        <Link to="/about" onClick={closeSidebar}>About Us</Link>
                    </nav>
                    <div className="sidebar-bottom">
                        {!authenticated ? (
                            <>
                                <Link to="/login" onClick={closeSidebar}>Login</Link>
                                <Link to="/signup" onClick={closeSidebar}>Signup</Link>
                            </>
                        ) : (
                            <>
                                <Link to="/profile" onClick={closeSidebar}>Profile</Link>
                                <Link to="/" onClick={handleLogOut}>Logout</Link>
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({ auth: state.auth });

export default connect(mapStateToProps)(HomeLayout);
