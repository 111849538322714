import React, { useEffect } from 'react';
import './TestLayout.css';
import { tips, livecls, plan, progress, ebooks, fullmock, vocab, temp, strategy,EngIcon,Img1,Img2,Img3,Img4,Time } from "../assets/Images/impoimg"; // Image Path
import { useNavigate } from 'react-router-dom';

const TestLayout = () => {
  const navigate = useNavigate();

  // Hide global navbar when this component mounts
  useEffect(() => {
    const globalNavbar = document.querySelector('.navbar');
    if (globalNavbar) {
      globalNavbar.style.display = 'none';
    }

    // Cleanup to show it again when this component unmounts
    return () => {
      if (globalNavbar) {
        globalNavbar.style.display = 'block';
      }
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll('.dynamic-animate');
      elements.forEach((element) => {
        const rect = element.getBoundingClientRect();
        if (rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)) {
          element.classList.add('in-view');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Navigation handler
  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="test-layout-wrapper">
      {/* Custom Navbar */}
      <nav className="custom-navbar">
      <ul>
          {/* Engdemy logo with separate class for styling */}
          <li className="logo-item" onClick={() => handleNavigation('/')}>
            <img src={EngIcon} alt="Engdemy Logo" />
          </li>
          <li onClick={() => handleNavigation('/')}>Home</li>
          <li onClick={() => handleNavigation('/practice')}>Practice</li>
          <li onClick={() => handleNavigation('/mock-tests')}>Mock Tests</li>
          <li onClick={() => handleNavigation('/buy')}>Buy</li>
          <li className="login" onClick={() => handleNavigation('/login')}>Log In</li>
          <li className="signup" onClick={() => handleNavigation('/signup')}>Signup</li>
        </ul>
      </nav>

      <div className="main-content">
        {/* Left Side Buttons */}
        <div className="left-sidebut">
          <button className="test-btn" onClick={() => handleNavigation('/practice')}>Speaking Questions</button>
          <button className="test-btn" onClick={() => handleNavigation('/writing')}>Writing Questions</button>
          <button className="test-btn" onClick={() => handleNavigation('/reading')}>Reading Questions</button>
          <button className="test-btn" onClick={() => handleNavigation('/listening')}>Listening Questions</button>
        </div>

        {/* Right Side Scaling GIF Containers */}
        <div className="right-sidetest">
          <div className="info-box grammar-tips" onClick={() => handleNavigation('/grammar-tips')}>
            <img src={tips} alt="Grammar Tips" />
            <p>Grammar Tips</p>
          </div>
          <div className="info-box live-class" onClick={() => handleNavigation('/live-classes')}>
            <img src={livecls} alt="Live Classes" />
            <p>Live Classes</p>
          </div>
          <div className="info-box study-plan" onClick={() => handleNavigation('/study-plan')}>
            <img src={plan} alt="Study Plan" />
            <p>Study Plan</p>
          </div>
          <div className="info-box check-progress" onClick={() => handleNavigation('/check-progress')}>
            <img src={progress} alt="Check Progress" />
            <p>Check Progress</p>
          </div>
        </div>
      </div>

      {/* Cards Section */}
      <div className="cards-section">
        <div className="card" onClick={() => handleNavigation('/vocabulary')}>
          <img src={vocab} alt="Vocabulary Bank" />
          <div className="card-text">Vocabulary Bank</div>
        </div>
        <div className="card" onClick={() => handleNavigation('/templates')}>
          <img src={temp} alt="Templates" />
          <div className="card-text">Templates</div>
        </div>
        <div className="card" onClick={() => handleNavigation('/strategic-videos')}>
          <img src={strategy} alt="Strategic Videos" />
          <div className="card-text">Strategic Videos</div>
        </div>
        <div className="card" onClick={() => handleNavigation('/ebooks')}>
          <img src={ebooks} alt="Ebooks" />
          <div className="card-text">Ebooks</div>
        </div>
        <div className="card" onClick={() => handleNavigation('/full-mock')}>
          <img src={fullmock} alt="Full Mock Test AI Scored" />
          <div className="card-text">Full Mock Test AI Scored</div>
        </div>
      </div>

        {/* New Section: 2x2 Image Grid and GIF */}
      <div className="new-section">
        <div className="image-grid">
          <div className="grid-item dynamic-animate" onClick={() => handleNavigation('/section1')}>
            <img src={Img1} alt="Section 1" />
          </div>
          <div className="grid-item dynamic-animate" onClick={() => handleNavigation('/section2')}>
            <img src={Img2} alt="Section 2" />
          </div>
          <div className="grid-item dynamic-animate" onClick={() => handleNavigation('/section3')}>
            <img src={Img3} alt="Section 3" />
          </div>
          <div className="grid-item dynamic-animate" onClick={() => handleNavigation('/section4')}>
            <img src={Img4} alt="Section 4" />
          </div>
        </div>
        <div className="gif-container dynamic-animate">
          <img src={Time} alt="Animated GIF" />
        </div>
      </div>

      {/* Navigation Footer */}
      <footer className="footer-nav">
        <ul>
          <li onClick={() => handleNavigation('/contact')}>Contact</li>
          <li onClick={() => handleNavigation('/terms')}>Disclaimer</li>
          <li onClick={() => handleNavigation('/privacy')}>Privacy Policy</li>
          <li onClick={() => handleNavigation('/terms')}>Terms of Service</li>
          <li onClick={() => handleNavigation('/terms')}>Refund & Cancellation Policy</li>
        </ul>
      </footer>

    </div>
  );
}

export default TestLayout;
