import React from 'react';
import './PrivacyPage.css';
import Particle from '../componets/Particle';
import option2 from "../assets/json/option2.json"; // Assuming a different particle effect

function PrivacyPage() {
    return (
        <div className="privacy-container h-screen flex justify-center items-center">
            <Particle option={option2} />
            <div className="privacy-content-wrapper max-h-screen overflow-y-scroll">
                <div className="privacy-content bg-white p-8 lg:p-12 shadow-lg rounded-lg text-gray-800 animate-fade-in">
                    <h1 className="text-3xl lg:text-4xl font-bold text-center mb-8 text-green-700">
                        Engdemy Education Inc. <span className="text-blue-700">Privacy Policy</span>
                    </h1>
                    <p className="text-gray-700 text-sm lg:text-lg mb-6 text-center">
                        <strong>Effective Date: September 22, 2024</strong>
                    </p>

                    <div className="privacy-section space-y-8">
                        {/* Section 1 */}
                        <div>
                            <h2 className="text-xl font-semibold text-green-600">1. Introduction</h2>
                            <p>
                                Engdemy Education Inc. ("Engdemy") is committed to protecting the privacy of our users. This Privacy Policy outlines how we collect, use, and protect your personal information when you use our English exam preparation website and app.
                            </p>
                        </div>

                        {/* Section 2 */}
                        <div>
                            <h2 className="text-xl font-semibold text-green-600">2. Information We Collect</h2>
                            <p><strong>When you use our services, we may collect the following information:</strong></p>
                            <p><strong>Personal Information:</strong></p>
                            <ul className="list-disc ml-5">
                                <li>Name</li>
                                <li>Email address</li>
                                <li>Password</li>
                                <li>Other information you provide voluntarily, such as in contact forms or when creating an account</li>
                            </ul>
                            <p><strong>Usage Information:</strong> Information about your interactions with our website and app, including pages visited, time spent on the site, and actions taken.</p>
                        </div>

                        {/* Section 3 */}
                        <div>
                            <h2 className="text-xl font-semibold text-green-600">3. How We Use Your Information</h2>
                            <p>We use your information for the following purposes:</p>
                            <ul className="list-disc ml-5">
                                <li><strong>Authentication:</strong> To verify your identity and allow you to access your account.</li>
                                <li><strong>Providing Services:</strong> To deliver our English exam preparation services and content.</li>
                                <li><strong>Improving Our Services:</strong> To analyze your usage patterns and make improvements to our website and app.</li>
                                <li><strong>Communication:</strong> To send you important notifications, updates, and marketing communications (if you have opted in).</li>
                            </ul>
                        </div>

                        {/* Section 4 */}
                        <div>
                            <h2 className="text-xl font-semibold text-green-600">4. Data Security</h2>
                            <p>
                                We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.
                            </p>
                        </div>

                        {/* Section 5 */}
                        <div>
                            <h2 className="text-xl font-semibold text-green-600">5. Data Retention</h2>
                            <p>
                                We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law.
                            </p>
                        </div>

                        {/* Section 6 */}
                        <div>
                            <h2 className="text-xl font-semibold text-green-600">6. Your Rights</h2>
                            <p>You have the following rights regarding your personal information:</p>
                            <ul className="list-disc ml-5">
                                <li><strong>Access:</strong> You can request access to the personal information we hold about you.</li>
                                <li><strong>Rectification:</strong> You can request that we correct any inaccurate or incomplete personal information.</li>
                                <li><strong>Erasure:</strong> You can request that we delete your personal information under certain circumstances, such as if it is no longer necessary for the purposes for which it was collected. </li>
                                <li><strong>Restriction of Processing:</strong> You can request that we restrict the processing of your personal information under certain circumstances.</li>
                                <li><strong>Data Portability:</strong> You can request that we provide your personal information in a structured, commonly used, and machine-readable format.</li>
                                <li><strong>Object to Processing:</strong> You can object to the processing of your personal information under certain circumstances.</li>
                            </ul>
                            <p>To exercise your rights, please contact us using the information provided below.</p>
                        </div>

                        {/* Section 7 */}
                        <div>
                            <h2 className="text-xl font-semibold text-green-600">7. Changes to This Privacy Policy</h2>
                            <p>
                                We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting a new version on our website.
                            </p>
                        </div>

                        {/* Section 8 */}
                        <div>
                            <h2 className="text-xl font-semibold text-green-600">8. Contact Us</h2>
                            <p>
                                If you have any questions about this Privacy Policy or our practices, please contact us at:
                                <br />
                                Engdemy Education Inc. <br />
                                <a href="mailto:info@engdemy.com">info@engdemy.com</a> <br />
                                <a href="https://www.engdemyeducation.com">www.engdemyeducation.com</a>
                            </p>
                        </div>

                        {/* Section 9 */}
                        <div>
                            <h2 className="text-xl font-semibold text-green-600">9. Governing Law</h2>
                            <p>
                                This Privacy Policy is governed by and construed in accordance with the laws of Canada.
                            </p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default PrivacyPage;
