// ScoreGraph.js
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels'; // Plugin for data labels

// Register chart elements
Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const ScoreGraph = ({ scores }) => {
  const data = {
    labels: ['Task Fulfillment', 'Coherence', 'Vocabulary', 'Grammar', 'Overall Score'],
    datasets: [
      {
        label: 'Score',
        data: [
          scores.taskFulfillmentScore || 0,
          scores.coherenceScore || 0,
          scores.vocabularyScore || 0,
          scores.grammarScore || 0,
          scores.overallScore || 0
        ],
        backgroundColor: [
          'rgba(75, 192, 192, 0.6)',
          'rgba(153, 102, 255, 0.6)',
          'rgba(255, 159, 64, 0.6)',
          'rgba(255, 206, 86, 0.6)',
          'rgba(0, 0, 0)'
        ],
        borderColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(0, 255, 0, 1)'
        ],
        borderWidth: 1,
        barThickness: 50, 
      },
    ],
  };

  const options = {
    responsive: true,
    indexAxis: 'y', // Horizontal bar chart
    plugins: {
      legend: {
        display: false, // Hide legend since each bar is labeled
      },
      title: {
        display: true,
        text: 'Speaking Test Scores',
        font: {
          size: 18, 
        },
      },
      datalabels: {
        color: '#000', // Label color
        anchor: 'end',
        align: 'right',
        font: {
          size: 15, // Increase font size for justification
          weight: 'bold', // Make score text bold
        },
        formatter: (value, context) => {
          // Access the justification based on the index
          const justifications = [
            scores.taskFulfillmentJustification || '',
            scores.coherenceJustification || '',
            scores.vocabularyJustification || '',
            scores.grammarJustification || '',
            scores.overallJustification || ''
          ];
          const justification = justifications[context.dataIndex]; // Get the correct justification
          return `${Math.round(value)} - ${justification}`; // Show score and justification
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        max: 14, // Keep the max value at 14
        grid: {
          display: false, // Disable internal grid lines
        },
      },
      y: {
        ticks: {
          color:'#000000',
          font: {
            size: 20, // Increase font size for better visibility
          },
        },
      },
    },
    animation: {
      duration: 2000, // Duration for dynamic effect
      easing: 'easeOutBounce', // Animation easing for a bounce effect
    },
    layout: {
      padding: {
        right: 550, // Increase right padding for justification
      },
    },
  };

  return (
    <div style={{ width: '100%', height: '600px',overflow:'visible' }}> 
      <Bar data={data} options={options} />
    </div>
  );
};

export default ScoreGraph;
