import React from 'react';
import './TermsPage.css';
import Particle from '../componets/Particle';
import option1 from "../assets/json/option1.json";
function TermsPage() {
    return (
        <div className="terms-container h-screen flex justify-center items-center">
            <Particle option={option1} />
            <div className="terms-content-wrapper max-h-screen overflow-y-scroll">
                <div className="terms-content bg-white p-8 lg:p-12 shadow-lg rounded-lg text-gray-800 animate-fade-in">
                    <h1 className="text-3xl lg:text-4xl font-bold text-center mb-8 text-red-700">
                        Engdemy Education Inc. <span className="text-blue-700">Terms of Service</span>
                    </h1>
                    <p className="text-gray-700 text-sm lg:text-lg mb-6 text-center">
                        <strong>Effective Date: September 22, 2024</strong>
                    </p>

                    <div className="terms-section space-y-8">
                        {/* Section 1 */}
                        <div>
                            <h2 className="text-xl font-semibold text-red-600">1. Introduction</h2>
                            <p>
                                These Terms of Service ("Terms") govern your use of the Engdemy Education Inc. ("Engdemy") website and app. By accessing or using our services, you agree to be bound by these Terms.
                            </p>
                        </div>

                        {/* Section 2 */}
                        <div>
                            <h2 className="text-xl font-semibold text-red-600">2. User Accounts</h2>
                            <p><strong>Account Creation and Information:</strong></p>
                            <ul className="list-disc ml-5">
                                <li>You may create a free or paid user account to access our services.</li>
                                <li>You must provide accurate and up-to-date information during the registration process.</li>
                                <li>You are responsible for maintaining the confidentiality of your account credentials and for any activities that occur under your account.</li>
                            </ul>
                            <p className="mt-2"><strong>Account Termination:</strong></p>
                            <ul className="list-disc ml-5">
                                <p>1. We may terminate your account at any time, without notice, for any reason, including but not limited to:</p>
                                <li>Violation of these Terms or our policies</li>
                                <li>Failure to pay for any fees or charges</li>
                                <li>Suspected fraudulent or illegal activity</li>
                                <p>2. Upon termination, you will lose access to your account and any content associated with it.</p>
                            </ul>
                        </div>

                        {/* Section 3 */}
                        <div>
                            <h2 className="text-xl font-semibold text-red-600">3. Our Services</h2>
                            <p><strong>Course Content:</strong></p>
                            <ul className="list-disc ml-5">
                                <li>We provide a variety of English exam preparation courses, including lectures, quizzes, assignments, and other educational materials.</li>
                                <li>Our courses are designed to help you improve your English language skills and prepare for specific exams.</li>
                                <li>We may update or modify our courses from time to time.</li>
                            </ul>
                            <p><strong>Access to Courses:</strong> Your access to courses will depend on your subscription plan or individual course purchases. We may limit your access to certain courses or features based on your account status.</p>
                            <p><strong>Course Completion and Certificates:</strong> Upon completion of a course, you may be eligible to receive a certificate of completion. Certificates are issued at our discretion and may be subject to certain requirements.</p>
                        </div>

                        {/* Section 4 */}
                        <div>
                            <h2 className="text-xl font-semibold text-red-600">4. Intellectual Property</h2>
                            <p><strong>Ownership:</strong> All content on our website and app, including courses, lectures, quizzes, assignments, and other materials, is our property or the property of our licensors.</p>
                            <p><strong>Use Restrictions:</strong> You may not reproduce, modify, distribute, or otherwise use our content for any commercial purpose without our prior written consent. You may not resell, sublicense, or transfer your access to our courses to others.</p>
                            <p><strong>Copyright Infringement:</strong> If you believe that your intellectual property rights have been infringed upon by our services, please notify us immediately.</p>
                        </div>

                        {/* Section 5 */}
                        <div>
                            <h2 className="text-xl font-semibold text-red-600">5. User Conduct</h2>
                            <p><strong>Prohibited Activities:</strong> You may not use our services to:</p>
                            <ul className="list-disc ml-5">
                                <li>Harass, threaten, or intimidate others</li>
                                <li>Post or transmit spam or unsolicited commercial messages</li>
                                <li>Violate the intellectual property rights of others</li>
                                <li>Engage in any other conduct that may harm or disrupt our services</li>
                            </ul>
                            <p><strong>Community Guidelines:</strong> We may have specific community guidelines that apply to your use of our services. By using our services, you agree to comply with these guidelines.</p>
                        </div>

                        {/* Section 6 */}
                        <div>
                            <h2 className="text-xl font-semibold text-red-600">6. Disclaimer of Warranties</h2>
                            <strong>No Warranties:</strong>
                            <ul className="list-disc ml-5">
                            <li> Our services are provided on an "AS IS" and "AS AVAILABLE" basis, without warranties of any kind, either express or implied.</li>
                                <li>We disclaim all warranties, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, and non-infringement.</li>
                            </ul>
                            <p><strong>No Guarantees:</strong> We do not guarantee that our services will meet your specific needs or that they will be error-free.</p>
                        </div>

                        {/* Section 7 */}
                        <div>
                            <h2 className="text-xl font-semibold text-red-600">7. Limitation of Liability</h2>
                            <p><strong>Liability Limitations:</strong> IN NO EVENT SHALL ENGDEMY BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES (EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), ARISING OUT OF OR IN CONNECTION WITH YOUR USE OR INABILITY TO USE OUR SERVICES. </p>
                            <p><strong>Indemnification:</strong> You agree to indemnify and hold Engdemy harmless from any claims, liabilities, damages, losses, or expenses arising out of or in connection with your use of our services or your violation of these Terms.</p>
                        </div>

                        {/* Section 8 */}
                        <div>
                            <h2 className="text-xl font-semibold text-red-600">8. Payment and Refunds</h2>
                            <p><strong>Payment:</strong> If you purchase a paid subscription or course, you will be charged the applicable fee. We may offer different payment options, such as credit card, PayPal, or other methods.</p>
                            <p><strong>Refunds:</strong> Our refund policy may vary depending on the type of purchase. Please refer to our specific refund policy for more information.</p>
                        </div>

                        {/* Section 9 */}
                        <div>
                            <h2 className="text-xl font-semibold text-red-600">9. Changes to These Terms</h2>
                            <strong>Updates:</strong>
                            <ul className="list-disc ml-5">
                            <li> We may update these Terms from time to time. We will notify you of any significant changes by posting a new version on our website.</li>
                            <p><li>Your continued use of our services after any changes to these Terms constitutes your acceptance of the revised Terms.</li></p>
                            </ul>
                        </div>

                        {/* Section 10 */}
                        <div>
                            <h2 className="text-xl font-semibold text-red-600">10. Governing Law</h2>
                            <p><strong>Jurisdiction:</strong> These Terms are governed by and construed in accordance with the laws of Canada, without regard to its conflict of law principles.</p>
                            <p><strong>Dispute Resolution:</strong> Any disputes arising out of or in connection with these Terms shall be resolved exclusively by the courts of [Jurisdiction].</p>
                        </div>

                        {/* Section 11 */}
                        <div>
                            <h2 className="text-xl font-semibold text-red-600">11. Contact Us</h2>
                            <p><strong>Customer Support:</strong> If you have any questions about these Terms or our services, please contact our customer support team.</p>
                        </div>

                        {/* Section 12 */}
                        <div>
                            <h2 className="text-xl font-semibold text-red-600">12. Additional Terms</h2>
                            <p><strong>Privacy Policy:</strong> Your use of our services is also subject to our Privacy Policy, which outlines our practices for collecting, using, and protecting your personal information.</p>
                            <p><strong>Other Policies:</strong> We may have additional policies or guidelines that apply to your use of our services.</p>
                        </div>
                        <p>By using our services, you acknowledge that you have read, understood, and agreed to be bound by these Terms.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TermsPage;
