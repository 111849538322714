import React from 'react';
import './LoadingAnimation.css'; // Import the CSS file for styling

const LoadingAnimation = () => {
    return (
        <div className="loading-container">
            <div className="loading-circle"></div>
            <div className="loading-text">Loading transcription and scores...</div>
        </div>
    );
};

export default LoadingAnimation;
